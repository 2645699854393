.headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .textAboveTitle {
        color: var(--a-text-subtle);
        font-size: var(--text-fluid-18-20);
        line-height: 1.5;
    }

    .header {
        font-size: var(--text-fluid-36-48);
        line-height: 1.3;
    }
}
